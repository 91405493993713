import { useMutation } from "@apollo/client/index.js";
import { gql } from "graphql-tag";
import { logger } from "../logger.client";

// The mutations
const TOGGLE_INTERACTION = gql`
  mutation toggleInteraction($postId: ID!, $type: String!) {
    toggleInteraction(postId: $postId, type: $type)
  }
`;

export enum InteractionType {
  LIKE = "like",
  BOOKMARK = "bookmark",
}

export function useInteractionsV2(postId: string, token: string) {
  const [toggleInteraction] = useMutation(TOGGLE_INTERACTION, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  /**
   * Handles an interaction between the user and the post. It will return the list of users who interacted with
   * the post in the same way.
   *
   * @param type The type of interaction to handle
   * @returns The list of users who interacted with the post in the same way
   */
  const handleInteraction = async (type: InteractionType): Promise<string[]> => {
    try {
      const interactionList = await toggleInteraction({
        variables: {
          postId,
          type,
        },
      });

      return interactionList.data.toggleInteraction;
    } catch (e) {
      logger.error(e);
    }
  };

  return {
    handleInteraction,
  };
}
