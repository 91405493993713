export enum LogEvent {
  CompanyProfile = "CompanyProfile",
  NotFound = "NotFound",
  Fatal = "FatalEvent",
  Subscription = "SubscriptionClass",
  ReactError = "ReactError",
  User = "UserClass",
  Company = "CompanyClass",
  ExportPosts = "ExportPosts",
  ExportPostsFailure = "ExportPostsFailure",
  Paywall = "PaywallClass",
  Post = "PostClass",
  Advertisement = "AdvertisementClass",
  FedSavvyReport = "AdvertisementClass",
  Session = "SessionClass",
  Interaction = "InteractionClass",
  FAQ = "FAQ",
}
