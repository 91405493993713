import { Cross1Icon } from "@radix-ui/react-icons";
import { Link } from "@remix-run/react";
import { Button, Flex, Heading, IconButton, ModalV2, Text } from "sparta";
import loginicon from "~/assets/interaction-login-modal-icon.svg";
import AuthButtons from "../shared/AuthButtons";

interface LoginModalProps {
  description: string;
  open: boolean;
  toggleModal: (e: any) => void;
  inactive?: boolean;
}

export function LoginModal({ open, toggleModal, description, inactive }: LoginModalProps) {
  return (
    <ModalV2.Root open={open} onOpenChange={toggleModal}>
      <ModalV2.Content>
        <ModalV2.Title className="loginModalTitle" style={{ textAlign: "center" }}>
          <Text>{inactive ? "Activate Subscription" : "Log in"}</Text>
          <IconButton onClick={toggleModal} variant="ghost">
            <Cross1Icon />
          </IconButton>
        </ModalV2.Title>
        <Flex direction="column" align="center" gap="32px" style={{ alignSelf: "stretch" }}>
          <img style={{ width: "206px", height: "206px" }} src={loginicon} alt="Login icon" />
          <Heading>{inactive ? "Subscription Required" : "Login Required"}</Heading>
          <Text>{description}</Text>
        </Flex>
        <Flex direction="column" align="center" gap="16px" mt="4">
          {inactive ? (
            <>
              <Button variant="solid">
                <Link to="/settings/billing" style={{ color: "white" }}>
                  Go to Billing Settings
                </Link>
              </Button>
              <Button onClick={toggleModal} variant="soft">
                Close
              </Button>
            </>
          ) : (
            <>
              <AuthButtons show />
              <Button onClick={toggleModal} variant="soft">
                Close
              </Button>
            </>
          )}
        </Flex>
      </ModalV2.Content>
    </ModalV2.Root>
  );
}
