import { CalendarIcon, FileTextIcon, HomeIcon, MagnifyingGlassIcon, StarIcon } from "@radix-ui/react-icons";
import { Cross1Icon } from "@radix-ui/react-icons"; // Import a close icon
import { Link } from "@remix-run/react";
import { Badge, Box, Flex, IconButton } from "sparta";
import { useDarkMode } from "sparta";
import g2xNewYearLogoDarkMode from "~/assets/g2xNewYearLogoDarkMode.gif";
import g2xNewYearLogoLightMode from "~/assets/g2xNewYearLogoLightMode.gif";
import { useMediaQuery } from "~/components/search/hooks/useMediaQuery";
import { useSideNav } from "~/context/SideNavContext";
import {
  CompaniesIcon,
  DefenseIcon,
  FedCivIcon,
  G2XLogo,
  GovAIQ,
  HealthIcon,
  OpportunitiesIcon,
  PeopleIcon,
  Pipeline,
} from "~/icons";
import { useRootLoaderData } from "~/root";
import { CollapsibleSection } from "./CollapsibleSection";
import { MenuButton } from "./MenuButton";
import { ResponsiveSideNavContainer } from "./ResponsiveSideNavContainer";

// Beta Badge Component
const BetaBadge = ({ active = false }: { active?: boolean }) => (
  <Badge
    style={{
      fontSize: "9px",
      padding: "2px 4px",
    }}
    ml="1"
    radius="large"
    size="1"
    variant={active ? "solid" : "soft"} // Conditionally set variant
    className="beta-badge"
    color="iris"
  >
    BETA
  </Badge>
);

interface SideNavProps {
  children: React.ReactNode;
}

export function SideNav({ children }: SideNavProps) {
  const { canSeeOpportunities, canSeeAwards, canSeePipelines } = useRootLoaderData();
  const { open, setOpen } = useSideNav(); // Access open state and setter
  const isMobile = useMediaQuery("(max-width: 760px)");

  return (
    <Flex gap="0">
      <ResponsiveSideNavContainer>
        <Flex direction="column" width="190px" p="3" style={{ borderRight: "1px solid var(--gray-a6)" }}>
          <Box position="relative">
            <Link to="/" prefetch="intent" className="G2X-logo">
              <G2XLogo />
            </Link>
            {isMobile && open && (
              <IconButton
                onClick={() => setOpen(false)}
                variant="ghost"
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  margin: "8px",
                }}
              >
                <Cross1Icon />
              </IconButton>
            )}
          </Box>
          <Flex direction="column" gap="4">
            <CollapsibleSection>
              <MenuButton icon={<HomeIcon />} title="Home" to="/" />
              <MenuButton icon={<CalendarIcon />} title="Events" to="/events" />
              <MenuButton icon={<MagnifyingGlassIcon />} title="Search" to="/search_v2" />
            </CollapsibleSection>
            <CollapsibleSection title="Daily Take">
              <MenuButton icon={<HealthIcon />} title="Health" to="/health" />
              <MenuButton icon={<FedCivIcon />} title="FedCiv" to="/fedciv" />
              <MenuButton icon={<DefenseIcon />} title="Defense" to="/defense" />
            </CollapsibleSection>
            <CollapsibleSection title="Intelligence">
              <MenuButton icon={<FileTextIcon />} title="Reports" to="/reports" />
              {canSeeOpportunities ? (
                <MenuButton
                  icon={<OpportunitiesIcon />}
                  title={(active) => (
                    <>
                      Opportunities <BetaBadge active={active} />
                    </>
                  )}
                  to="/opportunities?ordering=-last_updated"
                />
              ) : null}
              {canSeeAwards ? (
                <CollapsibleSection
                  isOpen={true}
                  variant="simple"
                  collapsable
                  title="Awards"
                  rightHeaderIcon={<StarIcon />}
                >
                  <MenuButton
                    icon={<Flex width="10px" />}
                    title={(active) => (
                      <>
                        Contracts <BetaBadge active={active} />
                      </>
                    )}
                    to="/awards/contracts"
                  />
                </CollapsibleSection>
              ) : null}
            </CollapsibleSection>
            <CollapsibleSection title="Directory">
              <MenuButton icon={<PeopleIcon />} title="People" to="/people" />
              <MenuButton
                icon={<CompaniesIcon />}
                title="Companies"
                to="/companies"
                match={["/companies", "/companies/search"]}
              />
            </CollapsibleSection>
            <CollapsibleSection title="TOOLS">
              <MenuButton
                newTab={true}
                icon={<GovAIQ />}
                title={
                  <>
                    GovAIQ <BetaBadge />
                  </>
                }
                to="https://govaiq.com/login"
              />
              {canSeePipelines && (
                <MenuButton
                  icon={<Pipeline />}
                  title={(active) => (
                    <>
                      Pipelines <BetaBadge active={active} />
                    </>
                  )}
                  to="/pipelines"
                />
              )}
            </CollapsibleSection>
          </Flex>
        </Flex>
      </ResponsiveSideNavContainer>
      {children}
    </Flex>
  );
}
