// src/components/Calendar/Calendar.tsx

import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import { DayPicker, DayPickerProps, SelectRangeEventHandler } from "react-day-picker";
import "./Calendar.css";
import CaptionWithYearSelector from "./CaptionWithYearSelector"; // Adjust the path as needed
import { Day } from "./components/Day";

export type CalendarProps = DayPickerProps & {
  direction?: "row" | "column";
};

const Calendar: React.FC<CalendarProps> = ({ className, classNames, showOutsideDays = true, direction, ...props }) => {
  const [currentMonth, setCurrentMonth] = useState<Date>(props.month || new Date());

  const handleMonthChange = (month: Date) => {
    setCurrentMonth(month);
  };

  const handleYearChange = (year: number) => {
    const newDate = new Date(currentMonth);
    newDate.setFullYear(year);
    setCurrentMonth(newDate);
  };

  return (
    <DayPicker
      month={currentMonth}
      onMonthChange={handleMonthChange}
      className={className}
      classNames={{
        months: `${direction === "column" ? "calendar-months" : "calendar-months-row"}`,
        month: "calendar-month",
        caption: "calendar-caption",
        caption_label: "calendar-caption-label",
        nav: "calendar-nav",
        nav_button: "calendar-nav-button",
        nav_button_previous: "calendar-nav-button-previous",
        nav_button_next: "calendar-nav-button-next",
        table: "calendar-table",
        head_row: "calendar-head-row",
        head_cell: "calendar-head-cell",
        row: "calendar-row",
        cell: props.mode === "range" ? "calendar-cell-range" : "calendar-cell",
        day: "calendar-day",
        day_range_start: "calendar-day-range-start",
        day_range_end: "calendar-day-range-end",
        day_selected: "calendar-day-selected",
        day_today: "calendar-day-today",
        day_outside: "calendar-day-outside",
        day_disabled: "calendar-day-disabled",
        day_range_middle: "calendar-day-range-middle",
        day_hidden: "calendar-day-hidden",
        ...classNames,
      }}
      showOutsideDays={showOutsideDays}
      components={{
        IconLeft: () => <ChevronLeftIcon />,
        IconRight: () => <ChevronRightIcon />,
        Day: (dayProps) => <Day {...dayProps} />,
        Caption: (captionProps) => (
          <CaptionWithYearSelector
            {...captionProps}
            onYearChange={handleYearChange}
            date={currentMonth}
            locale={"en-US"}
            onMonthChange={handleMonthChange}
          />
        ),
      }}
      {...props}
    />
  );
};

Calendar.displayName = "Calendar";

export { Calendar };
