// src/components/Calendar/components/CaptionWithYearSelector.tsx

import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import React from "react";
import { Button, CaptionProps } from "react-day-picker";
import { Flex } from "../Flex/Flex";
import { Select } from "../Select/Select";

type CaptionWithYearSelectorProps = CaptionProps & {
  onYearChange: (year: number) => void;
  date: Date;
  locale: string;
  onMonthChange: (date: Date) => void;
};

const CaptionWithYearSelector: React.FC<CaptionWithYearSelectorProps> = ({
  date,
  locale,
  onMonthChange,
  onYearChange,
}) => {
  const currentYear = date.getFullYear();

  // Defining the range of years we want to allow
  const startYear = 1900;
  const endYear = 2100;
  const years: string[] = [];
  for (let y = startYear; y <= endYear; y++) {
    years.push(y.toString());
  }

  const handleYearChange = (value: string) => {
    const newYear = parseInt(value, 10);
    onYearChange(newYear);
  };

  return (
    <div className="calendar-caption">
      <Button
        className="calendar-nav-button calendar-nav-button-previous"
        onClick={() => onMonthChange(new Date(date.getFullYear(), date.getMonth() - 1))}
      >
        <ChevronLeftIcon />
      </Button>
      <Flex align="center" justify="center" className="calendar-caption-label">
        {date.toLocaleString(locale, { month: "long" })}{" "}
        <Select
          options={years}
          value={currentYear.toString()}
          onChange={handleYearChange}
          placeholder="Select Year"
          className="year-select"
        />
      </Flex>
      <Button
        className="calendar-nav-button calendar-nav-button-next"
        onClick={() => onMonthChange(new Date(date.getFullYear(), date.getMonth() + 1))}
      >
        <ChevronRightIcon />
      </Button>
    </div>
  );
};

export default CaptionWithYearSelector;
