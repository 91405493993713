import log from "loglevel";

type LogLevel = "debug" | "info" | "warn" | "error";

const STYLES = {
  debug: "color: #7B68EE; font-weight: bold;", // Medium slate blue
  info: "color: #4682B4; font-weight: bold;", // Steel blue
  warn: "color: #FFA500; font-weight: bold;", // Orange
  error: "color: #DC143C; font-weight: bold;", // Crimson
} as const;

const getLogLevel = (): LogLevel => {
  if (typeof window === "undefined") return "warn";

  switch (process.env.NODE_ENV) {
    case "development":
      return "debug";
    case "test":
      return "info";
    case "production":
      return "warn";
    default:
      return "info";
  }
};

const baseLogger = log.getLogger("qs-app");
baseLogger.setLevel(log.levels[getLogLevel().toUpperCase()]);

const createLogger =
  (level: LogLevel) =>
  (...args: unknown[]) => {
    const timestamp = new Date().toISOString();
    const prefix = `%c${timestamp} [${level.toUpperCase()}]`;
    baseLogger[level](prefix, STYLES[level], ...args);
  };

export const logger = {
  debug: createLogger("debug"),
  info: createLogger("info"),
  warn: createLogger("warn"),
  error: createLogger("error"),
} as const;
