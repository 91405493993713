import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { useEffect, useRef, useState } from "react";
import { Button, Flex } from "sparta";

interface TagGroupProps {
  tags: JSX.Element[];
  placeholder?: boolean;
  openCloseMoreTagsModal: (e) => void;
}

export function TagGroup({ tags, placeholder = true, openCloseMoreTagsModal }: TagGroupProps) {
  const postTags = useRef<HTMLDivElement>(null);
  const [showMore] = useState(false);
  const [moreBtn, setMoreBtn] = useState(false);

  useEffect(() => {
    if (postTags.current) {
      const { scrollWidth, clientWidth } = postTags.current;
      // Check if the tags overflow
      if (scrollWidth > clientWidth) {
        setMoreBtn(true);
      }
    }
  }, [postTags]);

  return (
    <>
      {!showMore && placeholder ? <div className="tags-placeholder" /> : null}
      <Flex align="center" width="100%" position="relative" id="tag-group">
        <Flex
          ref={postTags}
          className="post-tags"
          style={
            showMore
              ? {
                  position: "unset",
                  whiteSpace: "pre-wrap",
                  height: "auto",
                  margin: 0,
                  marginRight: 20,
                }
              : {}
          }
        >
          {tags.length > 0 ? tags : null}
        </Flex>
        {moreBtn ? (
          <Button
            style={{ width: "25%", height: "90%" }}
            radius="full"
            onClick={openCloseMoreTagsModal}
            variant="outline"
          >
            <DotsHorizontalIcon />
          </Button>
        ) : null}
      </Flex>
    </>
  );
}
