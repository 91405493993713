import type { Hit } from "instantsearch.js";
import { useAtom } from "jotai";
import { Badge, Checkbox, Flex, Text } from "sparta";
import type { IPostHit } from "~/components/search/types";
import { exportModeAtom } from "~/utils/atoms";
import MoreButton from "./MoreButton";

interface IInsightsAndActions {
  hit: Hit<IPostHit>;
  showExport?: boolean;
  formattedPublishedAt: string;
  compact?: boolean;
  selected?: boolean;
  onSelect?: (e: any) => void;
}

export default function InsightsAndActions({
  hit,
  showExport,
  formattedPublishedAt,
  compact,
  selected,
  onSelect,
}: IInsightsAndActions) {
  const [exportMode] = useAtom(exportModeAtom);
  return (
    <Flex justify="between" align="center" flexGrow="0">
      <Flex align="center">
        <Text color="gray">{hit.category ? hit.category : hit.postType}</Text> <div className="bullet" />{" "}
        {!compact ? (
          <>
            <Text style={{ color: "var(--accent-9)" }}>{hit.readTime || "~1"} min read</Text>
          </>
        ) : null}
        <Text color="gray" size="2">
          {formattedPublishedAt}
        </Text>
      </Flex>
      {exportMode === "pdf" ? (
        <Checkbox
          checked={selected}
          onClick={(e) => {
            onSelect(e);
          }}
        />
      ) : null}
      <MoreButton showExport={showExport} postId={hit.id} />
    </Flex>
  );
}
