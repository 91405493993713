import { TextField } from "@radix-ui/themes";
import React, { forwardRef, useId } from "react";
import { useField } from "remix-validated-form";
import { Text } from "../Text/Text";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

type TextInputProps = TextField.RootProps & {
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  label?: string;
  hint?: string;
  formId?: string;
  labelWidth?: string;
  fixMobileAutoZoom?: boolean;
  width?: string;
  style?: React.CSSProperties;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      hint,
      size = "2",
      radius = "medium",
      name,
      leftSection,
      rightSection,
      formId,
      labelWidth = "100%",
      fixMobileAutoZoom = false,
      width,
      style,
      ...props
    },
    forwardedRef,
  ) => {
    const randId = useId();
    const fieldName = name || randId;
    const compFormId = formId || (name ? undefined : `${randId}-form`);
    const { error, getInputProps } = useField(fieldName, { formId: compFormId });
    
    // Detect if in mobile view
    const isMobile = useMediaQuery("(max-width: 768px)");

    // Compute styles for textfield if fixMobileAutoZoom is true and isMobile
    const inputStyleAux = fixMobileAutoZoom && isMobile ? { fontSize: '16px' } : {};
    const inputStyle = Object.assign({}, inputStyleAux, style ? style : {});


    const component = (
      <TextField.Root
        size={size}
        radius={radius}
        {...getInputProps({ id: name })}
        {...props}
        ref={forwardedRef}
        style={{...inputStyle, ...{ width: width }}}
      >
        {leftSection && (
          <TextField.Slot side="left"> {leftSection}</TextField.Slot>
        )}
        {props.children}
        {rightSection && (
          <TextField.Slot side="right">{rightSection}</TextField.Slot>
        )}
      </TextField.Root>
    );

    if (label) {
      return (
        <label style={{ width: labelWidth }}>
          <Text as="div" size="2" mb="1" weight="bold">
            {label}
          </Text>
          {component}
          {hint && !error ? (
            <Text as="div" size="1" color="gray" mt="1">
              {hint}
            </Text>
          ) : null}
          {error ? (
            <Text as="div" size="1" color="red" mt="1">
              {error}
            </Text>
          ) : null}
        </label>
      );
    }
    return component;
  },
);
